<template>
  <div class="lb-nav-content-right">
    <template v-if="status === 'authenticated'">
      <UserDropdownMenu>
        {{ firstName }}
      </UserDropdownMenu>
    </template>

    <template v-else-if="status === 'unauthenticated'">
      <NuxtLinkLocale href="/login" class="lb-nav-item-rgt">
        {{ $t("auth.sign-in") }}
      </NuxtLinkLocale>
      <NuxtLinkLocale href="/register" class="lb-nav-item-rgt">
        {{ $t("auth.sign-up") }}
      </NuxtLinkLocale>
    </template>

    <UIMobileUserButton />

    <!-- Sepet Bölümü-->
    <ShoppingCartMenu class="group order-6">
      <ShoppingCartIcon class="w-5 h-5 text-primary group-hover:text-secondary"/>
    </ShoppingCartMenu>
  </div>
</template>

<script setup>
import { ShoppingCartIcon } from "@heroicons/vue/24/outline";

const {status, data} = await useAuth()
const firstName = computed(() => (unref(data)?.user?.name).split(' ').slice(0, -1).join(' ') ? (unref(data)?.user?.name).split(' ').slice(0, -1).join(' ') : (unref(data)?.user?.name).split('@').slice(0, -1).join(' '))

</script>

<style lang="scss">
.lb-nav-content-right {
  @apply flex items-center justify-end lg:justify-between gap-0 lg:gap-5 order-4 col-span-2;
}
</style>